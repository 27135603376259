import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { default as classNames, default as classnames } from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setPromoRedirect } from '../../../redux/customer/actions';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import Hero from '../components/Hero/Hero';
import HeroTitle from '../components/Hero/HeroTitle';
import dynamic from 'next/dynamic';
import { parseArrFromString } from '../../../shared/utility';
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';

const AppStoreButton = dynamic(() => import('../components/CustomButtonsWebsite/AppStoreButton'))
const ExchangeInputBox = dynamic(() => import('../components/ExchangeInputBox/ExchangeInputBox'))
const HorizontalExchangeConverter = dynamic(() => import('../components/horizontalCurrencyConverter/HorizontalExchangeConverter'))

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        textAlign: 'left',
        paddingTop: '40px',
        paddingBottom: '100px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            paddingTop: '80px',
            paddingBottom: '60px',
            clipPath: 'none',
        },
    },
    curve: {
        zIndex: '10',
        background: '#ffffff',
        marginTop: '-30px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    curveInverted: {
        background: '#1160F7',
        marginTop: '-10px',
        marginBottom: '-10px',
    },
    heroDiv: {
        textAlign: 'right',
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    heroDivTranformed: {
        "@media (min-width: 700px) and (max-width: 959px)": {
            marginBottom: '40px',
        },
        "@media (max-width: 700px)": {
            marginBottom: '40px',
        },
    },
    imageTranformed: {
        transition: 'ease-in-out 0.5s',
        maxWidth: '100% !important',
    },

    main: {
        background: 'transparent',
        color: "'#31517A !important'",
        paddingTop: '4px',
        paddingBottom: '14px',
    },
    image: {
        maxWidth: '80%',
        zIndex: '20',
        "@media (min-width: 700px) and (max-width: 959px)": {
            paddingTop: '40px',
            textAlign: 'center !important',
            maxWidth: '50%',
            margin: 'auto',
            display: 'block',
        },
        "@media (max-width: 700px)": {
            maxWidth: '50%',
            paddingTop: '40px',
            margin: 'auto',
            display: 'block',
        },
    },
    imageFullWidth: {
        maxWidth: '100%',
    },
    hideCurved: {
        "@media (max-width: 959px)": {
            display: 'none',
        },
    },
    logoutMobileStyles: {
        marginTop: '400px'
    },
    hideDiagonal: {
        "@media (max-width: 959px)": {
            display: 'none',
        },
    },
    container: {
        paddingRight: "0px",
        paddingLeft: "15px",
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: '1140px',
        gap: '30px',
        '@media (min-width: 576px)': {
            maxWidth: "540px"
        },
        '@media (min-width: 768px)': {
            maxWidth: "720px"
        },
        '@media (min-width: 958px)': {
            maxWidth: "940px"
        },
        '@media (min-width: 992px)': {
            maxWidth: "960px"
        },
        '@media (min-width: 1200px)': {
            maxWidth: "1140px"
        }
    },
    overLay: {
        position: 'absolute',
        zIndex: '20',
        width: '100%',
        height: '100%'
    },
    overLapcontainer: {
        position: 'relative',
        marginTop: '10px',
    },
    phone: {
        marginLeft: 'auto',
        '@media (max-width: 960px)': {
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center !important'
        },
        '& img': {
            float: 'right',
            "@media (max-width: 960px)": {
                float: 'none !important'
            },
        }
    },
}))

const getRightSection = (type, backgroundImage, imageUrl, imageAlt, rightSectionProps, short, imageFullWidth, page, horizontalExchangeType) => {
    const classes = useStyles();
    const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));

    switch (type) {
        case 'ExchangeInputBox': return <ExchangeInputBox unavailable={rightSectionProps && rightSectionProps.length && rightSectionProps[0].unavailable} currencySelected={rightSectionProps && rightSectionProps.length && rightSectionProps[0].currencySelected} countryIdSelected={rightSectionProps && rightSectionProps.length && rightSectionProps[0].countryIdSelected} defaultTransferValue={rightSectionProps && rightSectionProps.length && rightSectionProps[0].defaultTransferValue} altBox={short} styles={classes} />;
        case 'HorizontalExchangeConverter': return <HorizontalExchangeConverter originalCountryId={rightSectionProps && rightSectionProps[0].originalCountryId} convertedCountryId={rightSectionProps && rightSectionProps[0].convertedCountryId} type={horizontalExchangeType} vertical />;
        default: return (
            page !== 'logout' || (page === 'logout' && !isNotMobile) ?
                <div className={classNames(classes.heroDiv, !backgroundImage && classes.heroDivTranformed)}>
                    <img
                        src={imageUrl}
                        alt={imageAlt}
                        className={classNames(classes.image, !backgroundImage && classes.imageTranformed, imageFullWidth && classes.imageFullWidth)}
                    />
                </div>
                : null
        )
    }
}

const getBottomSection = (type, backgroundImage, imageUrl, imageAlt, bottomSectionProps, short, imageFullWidth, goToLink, horizontalExchangeType) => {
    switch (type) {
        case 'HorizontalExchangeConverter': return <HorizontalExchangeConverter originalCountryId={bottomSectionProps && bottomSectionProps[0].originalCountryId} convertedCountryId={bottomSectionProps && bottomSectionProps[0].convertedCountryId} type={horizontalExchangeType} />;
        default: return <HorizontalExchangeConverter originalCountryId={bottomSectionProps && bottomSectionProps[0].originalCountryId} convertedCountryId={bottomSectionProps && bottomSectionProps[0].convertedCountryId} type={horizontalExchangeType} reroute={goToLink} />;
    }
}

function Component({
    title,
    text,
    imageUrl,
    imageAlt,
    actionText,
    curvedBottomColor,
    curvedBottomInverted,
    actionUrl,
    actionType,
    isVertical,
    rightSection,
    rightSectionType,
    rightSectionProps,
    bottomSection,
    bottomSectionType,
    bottomSectionProps,
    imageFullWidth,
    backgroundImage,
    page,
    short,
    promotion,
    promoRedirect,
    hideCurved,
    setPromoRedirect,
    horizontalExchangeType,
    horizontalExchangeRoute
}) {
    let goToLink = null;
    if (horizontalExchangeRoute && horizontalExchangeType) {
        goToLink = () => {
            router.push({ pathname: horizontalExchangeRoute });
        }
    }
    const classes = useStyles();
    const router = useRouter();
    const [fullTitle, setFullTitle] = useState(title);
    const [fullText, setFullText] = useState(text);
    const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));

    if (rightSectionProps) {
        rightSectionProps = parseArrFromString(rightSectionProps);
    }
    if (bottomSectionProps) {
        bottomSectionProps = parseArrFromString(bottomSectionProps);
    }

    useEffect(() => {
        let newText = '';
        if (router && router.asPath && router.asPath.includes('currency-converter') && router.query && router.query.amount) {
            const fromCode = router.asPath.substring(28, 31).toUpperCase();
            const toCode = router.asPath.substring(35, 38).toUpperCase();
            const amount = router.query.amount || '';
            if (amount && fromCode && toCode) {
                const title1 = `${amount} ${fromCode.toUpperCase()} Canadian dollar to`;
                const title2 = `${toCode.toUpperCase()} Exchange Rate`;
                const newTitle = <span style={{ fontSize: '52px' }}>{title1}&nbsp;{title2}&nbsp;</span>
                newText = `Convert ${amount} CAD to ${toCode} instantly with our Remitbee Currency Converter. Track historical exchange rates, see live CAD/${toCode} charts, and get free international money transfers for over 100 currencies`
                setFullTitle(newTitle);
                setFullText(newText);
            }
        } else {
            setFullTitle(title);
            setFullText(newText);
        }
    }, [title])

    rightSection = getRightSection(rightSectionType, backgroundImage, imageUrl, imageAlt, rightSectionProps, short, imageFullWidth, page);
    bottomSection = getBottomSection(bottomSectionType, backgroundImage, imageUrl, imageAlt, bottomSectionProps, short, imageFullWidth, horizontalExchangeRoute && goToLink, horizontalExchangeType && horizontalExchangeType);

    const goToUrl = async () => {
        let query = null;
        if (actionUrl && actionUrl.includes('signup')) {
            await setRedirectSignupCookie(router.asPath)
        }
        if (promotion) {
            query = addReferralLink()
            if (promoRedirect) setPromoRedirect(promoRedirect);
        }
        router.push({ pathname: actionUrl, query });
    }

    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    return (
        <div className={page === 'logout' ? classes.overLapcontainer : null}>
            {page === 'logout' && isNotMobile ?
                <div className={classes.overLay}>
                    <section className={classnames(classes.root)}>
                        <Grid
                            container
                            className={classes.container + ' ' + classes.alignContainer}
                            alignItems="center"
                        >
                            <Grid item xs={12} md={6} className={classes.phone}>
                                <img src={imageUrl} alt="logout image" />
                            </Grid>
                        </Grid>
                    </section>
                </div>
                : null
            }
            <Hero
                className={!backgroundImage && classes.main}
                backgroundImage={backgroundImage || null}
                isVertical={isVertical}
                leftSection={
                    <HeroTitle
                        title={fullTitle}
                        text={fullText}
                        className={!backgroundImage && classes.main}
                        isActionTextHidden={rightSectionType === 'ExchangeInputBox'}
                    >
                        {actionText &&
                            <CustomButton
                                type={actionType || "outlinedWhite"}
                                onClick={goToUrl}
                            // href={actionUrl}
                            >
                                {actionText}
                            </CustomButton>
                        }
                        {page === 'logout' &&
                            <AppStoreButton />
                        }
                    </HeroTitle>
                }
                rightSection={rightSection}
                topSection={
                    <HeroTitle
                        title={fullTitle}
                        text={fullText}
                        className={!backgroundImage && classes.main}
                        isActionTextHidden={true}
                    />
                }
                bottomSection={bottomSection}
                short={short}
                type={rightSectionType}
            />
            {curvedBottomColor && curvedBottomInverted ?
                <div className={classnames(curvedBottomInverted ? classes.curveInverted : classes.curve, hideCurved ? classes.hideCurved : null)}>
                    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1968 315">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1" fill={curvedBottomColor}>
                                <path className="cls-1" d="M0,238s892.5-300.63,1968,0v101.3H0Z" />
                            </g>
                        </g>
                    </svg>
                </div>
                :
                curvedBottomColor ?
                    <div className={classnames(curvedBottomInverted ? classes.curveInverted : classes.curve, hideCurved ? classes.hideCurved : null)}>
                        <svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg">
                            <g fill={curvedBottomColor}><path d="M640 140C286.54 140 0 0 0 0h1280S993.46 140 640 140z" />
                            </g>
                        </svg>
                    </div>
                    :
                    null
            }
            {page === 'logout' ?
                <div className={classnames(!isNotMobile ? classes.hideDiagonal : null)}>
                    <svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg">
                        <g fill="#105FF8">
                            <path d="M1360 0 L0 80 L0 0" />
                        </g>
                    </svg>
                </div>
                : null
            }
        </div>
    )
}

export default connect(null, { setPromoRedirect })(Component);
